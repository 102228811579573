// const commonUrl = "ImgUp/"; //公共路径
// const commonUrl = "http://bsgl.63lud.cn/api/"; //公共路径
// const commonUrl = "http://192.168.0.16:8011/api/"; //公共路径
// const commonUrl = 'api/' // 公共路径

// var commonUrl;
// if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
// } else {
//     commonUrl = "http://csdl.63lud.cn/api/"; //公共路径
// }
//     commonUrl = "api/"; //公共路径

// post请求封装
function postRequest (url, data) {
  return new Promise((resolve, reject) => {
    Axios.post(url, data)
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
  })
}
// function postRequest(url, data) {
//     var promise = new Promise((resolve, reject) => {
//         var that = this;
//         var postData = data;
//         // var postData = JSON.stringify(data);
//         Axios({
//             // url: commonUrl + url,
//             url: url,
//             data: postData,
//             // data: JSON.parse(postData),
//             method: "POST",
//             header: {
//                 "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
//                 // 'Authorization' : 'Bearer ' + uni.getStorageSync("token")
// 				 // token: uni.getStorageSync("token")
//             },
//             success: function(res) {
//                 //返回什么就相应的做调整
// 				// uni.hideLoading();
//                 if (res.statusCode == 200) {
//                     resolve(res.data);
//                 } else {
//                     // 请求服务器成功，但是由于服务器没有数据返回，此时无code。会导致这个空数据
//                     //接口后面的then执行
//                     // 不下去，导致报错，所以还是要resolve下，这样起码有个返回值，
//                     //不会被阻断在那里执行不下去！
//                     resolve(res.data.msg);
//                 }
//             },
//             error: function(e) {
// 				// uni.hideLoading();
//                 reject("网络出错");
//             }
//         });
//     });
//     return promise;
// }

// get请求封装
function getRequest (url, params = {}) {
  return new Promise((resolve, reject) => {
    Axios.get(url, {
      params: params
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
// function getRequest(url, data) {
//     var promise = new Promise((resolve, reject) => {
//         var that = this;
//         var postData = data;
//         Axios({
//             url: url,
//             // url: commonUrl + url,
//             data: postData,
//             method: "GET",
//             dataType: "json",
//             header: {
//                 "content-type": "application/json"
//             },
//             success: function(res) {
//                 if (res.statusCode == 200) {
//                     resolve(res.data);
//                 } else {
//                     resolve(res.data);
//                 }
//             },
//             error: function(e) {
//                 reject("网络出错");
//             }
//         });
//     });
//     return promise;
// }

module.exports = {
  post: postRequest,
  get: getRequest
}
