/*
    共通方法和共通变量写在这里
   */
const data = {
	ClassLists: [{ // 班级选项数量
		value: '1',
		label: '一班'
	}, {
		value: '2',
		label: '二班'
	}, {
		value: '3',
		label: '三班'
	}, {
		value: '4',
		label: '四班'
	}, {
		value: '5',
		label: '五班'
	}, {
		value: '6',
		label: '六班'
	}, {
		value: '7',
		label: '七班'
	}, {
		value: '8',
		label: '八班'
	}, {
		value: '9',
		label: '九班'
	}, {
		value: '10',
		label: '十班'
	}, {
		value: '11',
		label: '十一班'
	}, {
		value: '12',
		label: '十二班'
	}, {
		value: '13',
		label: '十三班'
	}, {
		value: '14',
		label: '十四班'
	}, {
		value: '15',
		label: '十五班'
	}, {
		value: '16',
		label: '十六班'
	}, {
		value: '17',
		label: '十七班'
	}, {
		value: '18',
		label: '十八班'
	}, {
		value: '19',
		label: '十九班'
	}, {
		value: '20',
		label: '二十班'
	}]
}

const func = {
	getMoney: function(num) { //将金额转换成以厘计算
		if (typeof(num) === 'string') {
			return parseFloat(num) * 1000
		} else {
			return num * 1000
		}
	},
	setMoney: function(num) { //将金额转换成以厘计算
		if (typeof(num) === 'string') {
			return parseFloat(num) / 1000
		} else {
			return num / 1000
		}
	},
	numFormat: function(num) { //余额每三位增加逗号
		var c = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() : num.toString().replace(
			/(\d)(?=(?:\d{3})+$)/g, '$1,');
		return c;
	},
	getBase64: function(url) { //将网络图片地址转换成base64
		return new Promise(resolve => {
			var canvas = document.createElement('canvas'),
				ctx = canvas.getContext('2d'),
				img = new Image;
			img.crossOrigin = 'Anonymous';
			img.onload = function() {
				canvas.height = img.height;
				canvas.width = img.width;
				ctx.drawImage(img, 0, 0);
				var baseImg = canvas.toDataURL('image/png');
				var file = func.base64toFile(baseImg,'imgFile');
				resolve(file);
				canvas = null;
			};
			img.src = url;
		})
	},
	base64toFile: function(base, filename) {	//将base64转换成文件
		var arr = base.split(',');
		var mime = arr[0].match(/:(.*?);/)[1];
		var bstr = atob(arr[1]);
		var n = bstr.length;
		var u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		//转换成file对象
		return new File([u8arr], filename, {
			type: mime
		});
	}
}

/*
	将秒级时间戳转换成字符串格式日期
 */
const dateFormat = function(val) {
	val *= 1000;
	let date = new Date();
	date.setTime(val);
	let y = date.getFullYear();
	let m = date.getMonth() + 1;
	let d = date.getDate();
	let h = date.getHours();
	let f = date.getMinutes();
	let s = date.getSeconds();
	return y + '-' + m + '-' + d + ' ' + h + ':' + f + ':' + s;
}

export default {
	data,
	func,
  dateFormat
}
