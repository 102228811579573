import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const adminName = ' _ 视云格总后台管理系统'

const router = new VueRouter({
	routes: [{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/index'),
		meta: {
			title: '登录' + adminName
		}
	},
	{
		path: '/',
		name: 'layout',
		component: () => import('@/views/layout.vue'),
		meta: {
			role: true
		},
		children: [
			{
				path: 'partnerList',
				name: 'partnerList',
				component: () => import('@/views/partner/partnerList.vue'),
				meta: {
					title: '合伙人列表'+ adminName
				}
			},
			{
				path: 'partnerLevelList',
				name: 'partnerLevelList',
				component: () => import('@/views/partner/partnerLevelList.vue'),
				meta: {
					title: '合伙人等级管理'+ adminName
				}
			},
			{
				path: 'agentList',
				name: 'agentList',
				component: () => import('@/views/equipment/agentList.vue'),
				meta: {
					title: '代理列表'+ adminName
				}
			},
			{
				path: 'agentLevelList',
				name: 'agentLevelList',
				component: () => import('@/views/equipment/agentLevelList.vue'),
				meta: {
					title: '等级管理'+ adminName
				}
			},
			// 没有这一页,添加代理用弹窗
			{
				path: 'equipment',
				name: 'equipment',
				component: () => import('@/views/equipment/equipment.vue'),
				meta: {
					title: '添加代理'
				}
			},
			{
				path: 'Didaudit',
				name: 'Didaudit',
				component: () => import('@/views/equipment/Didaudit.vue'),
				meta: {
					title: '待审核'+ adminName
				}
			},
			{
				path: 'Alreadyagree',
				name: 'Alreadyagree',
				component: () => import('@/views/equipment/Alreadyagree.vue'),
				meta: {
					title: '已同意 '+ adminName
				}
			},
			{
				path: 'Alreadyrefuse',
				name: 'Alreadyrefuse',
				component: () => import('@/views/equipment/Alreadyrefuse.vue'),
				meta: {
					title: '已拒绝'+ adminName
				}
			},
			{
				path: 'advUserList',
				name: 'advUserList',
				component: () => import('@/views/advUser/advUserList.vue'),
				meta: {
					title: '广告主列表'+ adminName
				}
			},
			{
				path: 'advList',
				name: 'advList',
				component: () => import('@/views/adv/advList.vue'),
				meta: {
					title: '广告列表'+ adminName
				}
			},
			{
				path: 'advFeedback',
				name: 'advFeedback',
				component: () => import('@/views/adv/advFeedback.vue'),
				meta: {
					title: '广告反馈'
				}
			},
			{
				path: 'advAuthList',
				name: 'advAuthList',
				component: () => import('@/views/advShangUser/advAuthList.vue'),
				meta: {
					title: '广告商申请列表'+ adminName
				}
			},
			{
				path: 'advAuthLog',
				name: 'advAuthLog',
				component: () => import('@/views/advShangUser/advAuthLog.vue'),
				meta: {
					title: '广告商审核记录列表'+ adminName
				}
			},
			// 这页内容不对
			{
				path: 'advUserDetail/:detailInfo',
				name: 'advUserDetail',
				component: () => import('@/views/advUser/advUserDetail.vue'),
				meta: {
					title: '充值详情'+ adminName
				}
			},
			{
				path: 'advUserRecharge/:detailInfo',
				name: 'advUserRecharge',
				component: () => import('@/views/advUser/advUserRecharge.vue'),
				meta: {
					title: '广告主充值'+ adminName
				}
			},
			{
				path: 'advShangList',
				name: 'advShangList',
				component: () => import('@/views/advShangUser/advShangList.vue'),
				meta: {
					title: '广告商列表'+ adminName
				}
			},
			{
				path: 'advShangUserRecharge/:detailInfo',
				name: 'advShangUserRecharge',
				component: () => import('@/views/advShangUser/advShangUserRecharge.vue'),
				meta: {
					title: '广告商充值'
				}
			},
			// 这页有点问题
			{
				path: 'advShangUserDetail/:detailInfo',
				name: 'advShangUserDetail',
				component: () => import('@/views/advShangUser/advShangUserDetail.vue'),
				meta: {
					title: '充值详情'+ adminName
				}
			},
			{
				path: 'realauthList',
				name: 'realauthList',
				component: () => import('@/views/realauth/realauthList.vue'),
				meta: {
					title: '实名认证列表'+ adminName
				}
			},
			{
				path: 'appealList',
				name: 'appealList',
				component: () => import('@/views/realauth/appealList.vue'),
				meta: {
					title: '实名认证申诉列表'+ adminName
				}
			},
			// 没有这一页
			{
				path: 'school',
				name: 'school',
				component: () => import('@/views/School/school.vue'),
				meta: {
					title: '添加学校'
				}
			},
			{
				path: 'schoolList',
				name: 'schoolList',
				component: () => import('@/views/School/schoolList.vue'),
				meta: {
					title: '学校列表'+ adminName
				}
			},
			{
				path: 'schoolInfo',
				name: 'schoolInfo',
				component: () => import('@/views/School/schoolInfo.vue'),
				meta: {
					title: '学校数据'
				}
			},
			{
				path: 'levelList/:schoolId/:schoolTypeId',
				name: 'levelList',
				component: () => import('@/views/School/levelList.vue'),
				meta: {
					title: '年级列表'+ adminName
				}
			},
			{
				path: 'classList/:levelId/:schoolId',
				name: 'classList',
				component: () => import('@/views/School/classList.vue'),
				meta: {
					title: '班级列表'+ adminName
				}
			},
			{
				path: 'studentList/:levelId/:schoolId/:classId',
				name: 'studentList',
				component: () => import('@/views/School/studentList.vue'),
				meta: {
					title: '学生列表'+ adminName
				}
			},
			{
				path: 'articleComplete',
				name: 'articleComplete',
				component: () => import('@/views/Article/articleComplete.vue'),
				meta: {
					title: '待审核'+ adminName
				}
			},
			{
				path: 'UNarticle',
				name: 'UNarticle',
				component: () => import('@/views/Article/UNarticle.vue'),
				meta: {
					title: '已审核'+ adminName
				}
			},
			{
				path: 'report',
				name: 'report',
				component: () => import('@/views/Article/report.vue'),
				meta: {
					title: '举报列表'+ adminName
				}
			},
			{
				path: 'refuseArticle',
				name: 'refuseArticle',
				component: () => import('@/views/Article/refuseArticle.vue'),
				meta: {
					title: '已拒绝'+ adminName
				}
			},
			{
				path: 'Addclassify',
				name: 'Addclassify',
				component: () => import('@/views/Article/Addclassify.vue'),
				meta: {
					title: '添加分类'+ adminName
				}
			},
			{
				path: 'freeze',
				name: 'freeze',
				component: () => import('@/views/Article/freeze.vue'),
				meta: {
					title: '已冻结'+ adminName
				}
			},
			{
				path: 'home',
				name: 'home',
				component: () => import('@/views/Home/home.vue'),
				meta: {
					title: '首页'+ adminName
				}
			},
			{
				path: 'machine',
				name: 'machine',
				component: () => import('@/views/machine/machine.vue'),
				meta: {
					title: '设备列表'+ adminName
				},
			},
			{
				path: 'machineBatch',
				name: 'machineBatch',
				component: () => import('@/views/machine/machineBatch.vue'),
				meta: {
					title: '批次管理'+ adminName
				},
			},
			{
				path: 'machines',
				name: 'machines',
				component: () => import('@/views/machine/machines.vue'),
				meta: {
					title: '设备批次列表'+ adminName
				}
			},
			{
				path: 'registrationDevice',
				name: 'registrationDevice',
				component: () => import('@/views/machine/registrationDevice.vue'),
				meta: {
					title: '注册申请'+ adminName
				}
			},
			{
				path: 'deviceDetails',
				name: 'deviceDetails',
				component: () => import('@/views/machine/deviceDetails.vue'),
				meta: {
					title: '查看详情'+ adminName
				}
			},
			{
				path: 'management',
				name: 'management',
				component: () => import('@/views/machine/management.vue'),
				meta: {
					title: '校温管理'+ adminName
				}
			},
			{
				path: 'machineVerify',
				name: 'machineVerify',
				component: () => import('@/views/machine/machineVerify.vue'),
				meta: {
					title: '设备异常登陆日志'+ adminName
				}
			},
			{
				path: 'machineVerifys',
				name: 'machineVerifys',
				component: () => import('@/views/machine/machineVerifys.vue'),
				meta: {
					title: '注册员日志'+ adminName
				}
			},
			{
				path: 'appFeedback',
				name: 'appFeedback',
				component: () => import('@/views/APP/appFeedback.vue'),
				meta: {
					title: 'APP问题反馈与投诉'
				}
			},
			{
				path: 'appAdmin',
				name: 'appAdmin',
				component: () => import('@/views/APP/appAdmin.vue'),
				meta: {
					title: 'APP首页数据修改'+ adminName
				}
			},
			{
				path: 'versionList',
				name: 'versionList',
				component: () => import('@/views/APP/versionList.vue'),
				meta: {
					title: '版本管理列表'+ adminName
				}
			},
			{
				path: 'appUpdate',
				name: 'appUpdate',
				component: () => import('@/views/APP/appUpdate.vue'),
				meta: {
					title: 'APP版本更新管理'+ adminName
				}
			},
			{
				path: 'versionUpdate/:id?',
				name: 'versionUpdate',
				component: () => import('@/views/APP/versionUpdate.vue'),
				meta: {
					title: '版本升级更新'+ adminName
				}
			},
			{
				path: 'pushNews',
				name: 'pushNews',
				component: () => import('@/views/APP/pushNews.vue'),
				meta: {
					title: '发布文章'+ adminName
				}
			},
			{
				path: 'userList',
				name: 'userList',
				component: () => import('@/views/APP/userList.vue'),
				meta: {
					title: 'APP用户列表'+ adminName
				}
			},
			{
				path: 'activityInfo',
				name: 'activityInfo',
				component: () => import('@/views/APP/activityInfo.vue'),
				meta: {
					title: '活动排名'+ adminName
				}
			},
			{
				path: 'screenList',
				name: 'screenList',
				component: () => import('@/views/screen/screenList.vue'),
				meta: {
					title: '大屏幕列表'
				}
			},
		]
	}
	]
})

export default router
